var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "comm_file_upload",
      staticStyle: { margin: "0", width: "100%" },
    },
    [
      _c(
        "div",
        {
          staticClass: "header",
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "justify-content": "space-between",
            width: "100%",
          },
        },
        [
          _c(
            "h1",
            {
              staticClass: "title",
              staticStyle: {
                color: "#0F376A",
                "font-size": "14px",
                "font-weight": "500",
                "text-align": "left",
                "align-items": "center",
              },
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _c(
            "el-upload",
            {
              staticClass: "upload-attachment",
              attrs: {
                multiple: false,
                action: _vm.fileUploadUrl,
                "file-list": _vm.fileList,
                "show-file-list": false,
                "before-upload": _vm.beforeFileUpload,
                "on-success": _vm.handleAttachmentUploadSuccess,
                "on-preview": _vm.handlePreview,
              },
            },
            [
              _vm.isShowBtn
                ? _c(
                    "el-button",
                    {
                      staticClass: "upload-action-button",
                      attrs: { round: "", outline: "", size: "mini" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.actionBtnLabel
                              ? _vm.actionBtnLabel
                              : _vm.$t("uploadFile")
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.isShowReminderBtn
            ? _c(
                "div",
                { staticClass: "flex-row" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "min-width": "100px" },
                      attrs: { round: "", type: "danger", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("sendNoaReminder")
                        },
                      },
                    },
                    [_vm._v(" Send NOA Acknowledgement Reminder ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.fileTableloading,
              expression: "fileTableloading",
            },
          ],
          attrs: {
            data: _vm.fileList,
            border: "",
            "header-cell-style": _vm.HEADER_CELL_STYLE,
            "cell-style": { ..._vm.CELL_STYLE, textAlign: "center" },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "File Name", width: "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "h4",
                      {
                        staticClass: "link-text",
                        on: {
                          click: function ($event) {
                            return _vm.handlePreview(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.fileName))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Date and Time Uploaded", "min-width": "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("h4", [_vm._v(_vm._s(scope.row.updatedAt))])]
                },
              },
            ]),
          }),
          _vm.isShowActionCheckBar && !_vm.isMultiple
            ? _c("el-table-column", {
                attrs: {
                  label: "Financier Acknowledged",
                  "min-width": "150px",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.checkedByFinancier
                            ? _c("el-button", {
                                attrs: {
                                  type: "success",
                                  icon: "el-icon-check",
                                  circle: "",
                                },
                              })
                            : _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled:
                                      _vm.$attrs.status !==
                                        _vm.FINANCE_STATUS[2] ||
                                      !_vm.$attrs.isFinancier,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleChangeCheckBox(false)
                                    },
                                  },
                                },
                                [_vm._v("Acknowledge")]
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1457948286
                ),
              })
            : _vm._e(),
          _vm.isShowActionCheckBar && !_vm.isMultiple
            ? _c("el-table-column", {
                attrs: { label: "Supplier Acknowledged", "min-width": "150px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.checkedBySupplier
                            ? _c("el-button", {
                                attrs: {
                                  type: "success",
                                  icon: "el-icon-check",
                                  circle: "",
                                },
                              })
                            : _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled:
                                      _vm.$attrs.status !==
                                        _vm.FINANCE_STATUS[2] ||
                                      _vm.$attrs.isFinancier,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleChangeCheckBox(false)
                                    },
                                  },
                                },
                                [_vm._v("Acknowledge")]
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3746473528
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "Remarks", "min-width": "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "textarea",
                        placeholder: "Please input your remarks",
                        disabled: !_vm.allowEdit,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.rowValueChange(scope.row, "remarks")
                        },
                      },
                      model: {
                        value: scope.row.remarks,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "remarks", $$v)
                        },
                        expression: "scope.row.remarks",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          !_vm.disabledUploadOrDeleteFiles
            ? _c("el-table-column", {
                attrs: { label: "", width: "50px", fixed: "right" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-popconfirm",
                            {
                              attrs: {
                                "confirm-button-text": "Delete",
                                "cancel-button-text": "No, Thanks",
                                icon: "el-icon-info",
                                "icon-color": "red",
                                title: "Are you sure to delete this?",
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.removeUploadedFile(scope.$index)
                                },
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "reference",
                                  size: "small",
                                  icon: "el-icon-delete",
                                  type: "danger",
                                  circle: "",
                                },
                                slot: "reference",
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  116844401
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("file-preview", {
        attrs: { visible: _vm.isOpenFilePreview, file: _vm.previewFile },
        on: {
          "update:visible": function ($event) {
            _vm.isOpenFilePreview = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }